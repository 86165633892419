import React from "react"
import { graphql } from "gatsby"
import Booking from '../components/Booking'
import Layout from '../components/Layout'
import Helmet from "react-helmet"
import Breadcrumb from "../components/Breadcrumb"
import favicon from "../../static/favicon.svg"
import { useLocation } from "@reach/router"
import {I18nextContext, useTranslation} from "gatsby-plugin-react-i18next";
import {useSiteMetadata} from "../hooks/use-site-metadata";

export const query = graphql`
	query ExperienceBookingDetails($experienceId: Int, $lang: String) {
		experience(lang: {eq: $lang}, experienceId: {eq: $experienceId}) {
      experienceId
      name
      name_spa
      languages
      difficulty
      difficulty_id
      subcategory {
        id
        name
        name_spa
      }
      duration {
          hours
          minutes
          formatted
      }
      products {
        id
        name
        name_spa
        weekDays
        languages
        rates {
          id
          pvp
          customerTypes {
            name
          }
        }
        lowestRate {
          formatted
        }
      }
      lowestRate {
          value
          formatted
          currency
      }
    }
	}
`

export default function BookingPage({ data : { experience } }) {
  const { t } = useTranslation("Index");
  const { title } = useSiteMetadata();
  const productId = new URLSearchParams(useLocation().search).get('productId');
  const { language } = React.useContext(I18nextContext);

  return (
    <Layout>
      <Helmet>
        <html lang={language}/>
        <title>{t(title)}</title>
        <link rel="icon" href={favicon}/>
        <meta name="title" content={title}/>
        <meta name="og:title" content={title}/>
        <meta name={`robots`} content={`noindex, nofollow`}/>
      </Helmet>
      <Breadcrumb step={'precio-y-disponibilidad'}/>
      <Booking experience={experience} productId={productId} />
    </Layout>
  )
}
